import { Controller } from "@hotwired/stimulus";
import { classNames } from "~/utils/class-names";

export default class extends Controller {
  static targets = ["error", "field"];

  static values = {
    id: String,
    classNames: Object,
  };

  connect() {
    this.classNames = classNames(this.classNamesValue);
    this.isError = this.isErrorValue;
  }

  setError(errorMessage) {
    if (!this.isError) {
      this.errorTarget.classList.remove(...this.classNames.hidden);
      var currentElement = this.fieldTarget.parentElement;
      while (currentElement) {
        if (currentElement.getAttribute("data-id") == this.idValue) {
          currentElement.classList.add(...this.classNames.error);
          break;
        }
        currentElement = currentElement.parentElement;
      }
      this.isError = true;
    }

    this.errorTarget.innerHTML = errorMessage;
  }

  preventEnter(e) {
    if (e.key === "Enter") {
      let formController = event.target.form.dataset.controller;
      if (
        formController === "report-generation-form" ||
        formController === "popup-form-form-contents" ||
        formController === "start-survey-form"
      ) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  unsetError() {
    this.errorTarget.classList.add(...this.classNames.hidden);
    var currentElement = this.fieldTarget.parentElement;
    while (currentElement) {
      if (currentElement.getAttribute("data-id") == this.idValue) {
        currentElement.classList.remove(...this.classNames.error);
        if (currentElement.querySelectorAll("div.error_message").length)
          currentElement.querySelectorAll("div.error_message")[0].remove();
        break;
      }
      currentElement = currentElement.parentElement;
    }
    this.isError = false;
  }

  updateErrorState() {
    const target = this.fieldTarget;
    if (target.checkValidity()) {
      // Check for max/min
      this.unsetError();
    } else {
      this.setError(this.fieldTarget.validationMessage);
    }
  }

  validateRange() {
    if (this.fieldTarget.checkValidity()) {
      const min = this.fieldTarget.getAttribute("data-min");
      const max = this.fieldTarget.getAttribute("data-max");

      var error_message = "";
      if (min && max) {
        error_message =
          "Value must be between " + min + " and " + max + " (inclusive)";
      } else if (min) {
        error_message = "Value must be greater than or equal to " + min;
      } else if (max) {
        error_message = "Value must be less than or equal to " + max;
      }

      if (
        min &&
        this.fieldTarget.value &&
        this.fieldTarget.value * 1 < min * 1
      ) {
        this.setError(error_message);
      } else if (
        max &&
        this.fieldTarget.value &&
        this.fieldTarget.value * 1 > max * 1
      ) {
        this.setError(error_message);
      }
    }
  }

  onChange() {
    this.updateErrorState();

    this.dispatch("change", {
      detail: {
        id: this.idValue,
        value: this.fieldTarget.value || undefined,
      },
    });
  }

  onBlur() {
    this.validateRange();
    const nextElement = this.fieldTarget.nextElementSibling;
    if (nextElement && nextElement.classList.contains("hint"))
      nextElement.classList.add(...this.classNames.hidden);
  }
  onFocus() {
    const nextElement = this.fieldTarget.nextElementSibling;
    if (nextElement && nextElement.classList.contains("hint"))
      nextElement.classList.remove(...this.classNames.hidden);
  }

  onBeforeInput(e) {
    const patternString = e.currentTarget.getAttribute("pattern");

    if (e.data !== null && patternString) {
      const pattern = new RegExp(patternString);
      const string = [e.currentTarget.value, e.data].filter((v) => v).join("");

      if (!pattern.test(string)) {
        e.preventDefault();
      }
    }
  }
}
