import { Controller } from "@hotwired/stimulus";
import { classNames } from "~/utils/class-names";
import {
  normalizeState,
  fieldHasValue,
  fieldIsFormField,
} from "~/utils/fields";

export default class extends Controller {
  static targets = [
    "button",
    "tab",
    "field",
    "counter",
    "counterFilled",
    "counterVisible",
    "allRequiredFilled",
    "closePopup",
    "refinePopup",
    "limitPopup",
    "formPopup",
  ];

  static values = {
    counterTemplate: String,
    limitReached: Boolean,
  };

  connect() {
    this.state = normalizeState(JSON.parse(this.element.dataset.state));
    this.classNames = classNames(JSON.parse(this.element.dataset.classNames));

    this.updateCounters();

    for (const field of this.getVisibleFields()) {
      if (field.error) {
        var target = document.getElementById(field.id + "_holder");
        var container = target.parentElement;
        while (container) {
          if (container.classList.contains("overflow-y-auto")) {
            break;
          }
          container = container.parentElement;
        }
        container;
        var errorDiv = document.createElement("div");
        errorDiv.classList.add("error_message");
        errorDiv.innerHTML =
          "<strong class='font-bold'>Error: </strong><span class='block sm:inline'>Please correct the issues below and try again.</span>";
        target.insertBefore(errorDiv, target.firstChild);

        // Get the position of the target element relative to the container
        var targetPosition = target.offsetTop - container.offsetTop;

        // Scroll to the target element with smooth scrolling
        container.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });

        break;
      }
    }
    if (this.element.dataset.autosubmit == "true")
      this.element.querySelector('button[name="submit_action"]').click();
  }

  // State helpers

  getCategory(categoryId) {
    return this.state.categories[categoryId];
  }

  getField(fieldId) {
    return this.state.fields[fieldId];
  }

  getFields() {
    return Object.values(this.state.categories)
      .flatMap((input_ids) => input_ids)
      .map((fieldId) => this.getField(fieldId))
      .filter((field) => fieldIsFormField(field));
  }

  getVisibleFields() {
    return this.getFields().filter(({ visible }) => visible);
  }

  getFilledFields() {
    return this.getVisibleFields().filter((field) => fieldHasValue(field));
  }

  // Category helpers

  getVisibleFieldsForCategory(categoryId) {
    return this.getCategory(categoryId).filter(
      (fieldId) =>
        this.getField(fieldId)?.visible &&
        fieldIsFormField(this.getField(fieldId))
    );
  }

  getFilledFieldsForCategory(categoryId) {
    return this.getVisibleFieldsForCategory(categoryId).filter((fieldId) =>
      fieldHasValue(this.getField(fieldId))
    );
  }

  areAllRequiredFieldsForCategoryFilled(id) {
    return this.getVisibleFieldsForCategory(id)
      .filter((fieldId) => this.getField(fieldId).required)
      .every((fieldId) => fieldHasValue(this.getField(fieldId)));
  }

  areAllRequiredFieldsAreFilled() {
    return Object.keys(this.state.categories).every((categoryId) =>
      this.areAllRequiredFieldsForCategoryFilled(categoryId)
    );
  }

  // Updaters

  updateVisibility() {
    Object.values(this.state.fields).forEach((fieldState) => {
      if (!fieldState.dependOn) {
        return;
      }

      fieldState.visible = fieldState.dependOn.some((dependencies) => {
        return dependencies.every((dependency) => {
          const parentField = this.getField(dependency.parent);

          if (!parentField) {
            return false;
          }

          return dependency.answers.some(
            (answer) =>
              parentField.value.has(answer) || parentField.value.has(answer * 1)
          );
        });
      });
    });

    this.fieldTargets.forEach((field) => {
      const fieldState = this.getField(field.dataset.id);

      if (fieldState?.visible) {
        field.classList.remove(...this.classNames.hidden);
      } else {
        field.classList.add(...this.classNames.hidden);
      }
    });
  }

  updateCounterText() {
    const visible = this.getVisibleFields().length;
    const filled = this.getFilledFields().length;

    this.counterFilledTarget.value = filled;
    this.counterVisibleTarget.value = visible;
    this.allRequiredFilledTarget.value = this.allRequriedFieldsAreFilled();

    this.counterTarget.innerHTML = this.counterTemplateValue
      .replace("{filled}", filled)
      .replace("{visible}", visible);
  }

  /*
   * @param {Node} counter
   * @param {string} text
   * @param {string} status - one of "default", "danger", "success"
   */

  updateCounter(counter, text, status) {
    counter.innerHTML = text;

    counter.classList.remove(
      ...this.classNames.hidden,
      ...this.classNames.dangerCounter,
      ...this.classNames.successCounter,
      ...this.classNames.defaultCounter
    );

    if (status === "success") {
      counter.classList.add(...this.classNames.successCounter);
    } else if (status === "danger") {
      counter.classList.add(...this.classNames.dangerCounter);
    } else {
      counter.classList.add(...this.classNames.defaultCounter);
    }
  }

  updateCounters() {
    this.buttonTargets.forEach((button) => {
      const id = button.dataset["categoryId"];

      const filled = this.getFilledFieldsForCategory(id).length;
      const visible = this.getVisibleFieldsForCategory(id).length;
      const counterText = `${filled}/${visible}`;

      let counterStatus = "default";

      if (filled === visible) {
        counterStatus = "success";
      } else if (!this.areAllRequiredFieldsForCategoryFilled(id)) {
        counterStatus = "danger";
      }
      if (button.querySelector("[data-counter]")) {
        this.updateCounter(
          button.querySelector("[data-counter]"),
          counterText,
          counterStatus
        );
      }
    });

    this.updateCounterText();
  }

  updateTabs(e) {
    const selectedTabId = e.currentTarget.dataset.categoryId;

    this.tabTargets.forEach((tab) => {
      if (tab.dataset.id === selectedTabId) {
        tab.classList.remove(...this.classNames.hidden);
        var container = tab.parentElement;
        while (container) {
          if (container.classList.contains("overflow-y-auto")) {
            break;
          }
          container = container.parentElement;
        }
        container.scrollTop = 0;
      } else {
        tab.classList.add(...this.classNames.hidden);
      }
    });

    this.buttonTargets.forEach((button) => {
      if (button.dataset["categoryId"] === selectedTabId) {
        button.classList.remove(...this.classNames.defaultTab);
        button.classList.add(...this.classNames.activeTab);
      } else {
        button.classList.remove(...this.classNames.activeTab);
        button.classList.add(...this.classNames.defaultTab);
      }
    });
  }

  update() {
    this.updateVisibility();
    this.updateCounters();
  }

  allRequriedFieldsAreFilled() {
    return Object.values(this.state.fields).every(({ required, id }) =>
      required ? fieldHasValue(this.getField(id)) : true
    );
  }

  onChange({ detail }) {
    const field = this.getField(detail.id);

    if (field) {
      field.value = detail.value;

      this.update();
    }
  }

  onSaveDraft() {
    this.skipCheck = true; // allows to save form even if submission report is reached
    this.element.noValidate = true;
    this.element.requestSubmit();
  }

  onSubmit(e) {
    if (this.invalidTitle()) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    if (!this.skipCheck === true) {
      if (this.limitReachedValue) {
        e.preventDefault();
        this.onToggleLimitPopup();
      }
    }
  }

  onCloseRefinePopup() {
    this.refinePopupTarget.classList.add(...this.classNames.hidden);
  }

  onToggleClosePopup(e) {
    if (e.params.close === true) {
      this.closePopupTarget.classList.add(...this.classNames.hidden);
    } else {
      this.closePopupTarget.classList.remove(...this.classNames.hidden);
    }
  }

  onToggleLimitPopup(e) {
    if (e?.params?.close === true) {
      this.limitPopupTarget.classList.add(...this.classNames.hidden);
    } else {
      this.limitPopupTarget.classList.remove(...this.classNames.hidden);
    }
  }

  invalidTitle() {
    const inputField = document.querySelector('input[name="title"]');
    const defaultValue = inputField.parentElement.getAttribute(
      "data-report-generation-form-title-default-value"
    );
    if (inputField.value.length <= 3 || inputField.value == defaultValue) {
      // Invalid title.  Raise error and stop save/run
      inputField.focus();
      const hintDiv = inputField.parentElement.querySelector("div.hint");
      hintDiv.classList.remove(...this.classNames.hidden);
      hintDiv.classList.add("flash");
      setTimeout(() => {
        hintDiv.classList.remove("flash");
      }, 750);
      return true;
    }
    return false;
  }
}
