import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["opened"];

  static targets = ["root"];

  onToggle() {
    const classList = this.rootTarget.classList;

    if (classList.contains(this.openedClass)) {
      classList.remove(this.openedClass);
    } else {
      classList.add(this.openedClass);
    }
  }
}
