import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["error"];

  static values = {
    id: String,
    state: Object,
    isError: Boolean,
    hiddenClassName: String,
  };

  connect() {
    this.state = new Map(Object.entries(this.stateValue));
    this.isError = this.isErrorValue;
    this.hiddenClassName = this.hiddenClassNameValue.split(" ");
  }

  setError(errorMessage) {
    if (!this.isError) {
      this.errorTarget.classList.remove(...this.hiddenClassName);
      var currentElement = this.errorTarget.parentElement;
      while (currentElement) {
        if (currentElement.getAttribute("data-id") == this.idValue) {
          currentElement.classList.add("error");
          break;
        }
        currentElement = currentElement.parentElement;
      }
      this.isError = true;
    }

    this.errorTarget.innerHTML = errorMessage;
  }

  unsetError() {
    this.errorTarget.classList.add(...this.hiddenClassName);
    var currentElement = this.errorTarget.parentElement;
    while (currentElement) {
      if (currentElement.getAttribute("data-id") == this.idValue) {
        currentElement.classList.remove("error");
        if (currentElement.querySelectorAll("div.error_message").length)
          currentElement.querySelectorAll("div.error_message")[0].remove();
        break;
      }
      currentElement = currentElement.parentElement;
    }
    this.isError = false;
  }

  onChangeCheckbox(event) {
    const hiddenClassName = this.hiddenClassNameValue.split(" ");
    const checkbox = event?.target;
    const fieldId = checkbox.dataset?.target;
    const field = document.getElementById(fieldId);
    const unit1 = document.getElementById(fieldId + "_unit1");
    const unit2 = document.getElementById(fieldId + "_unit2");

    if (this.isError) {
      this.unsetError();
    }

    if (checkbox.checked) {
      if (field) {
        field.classList.remove(...hiddenClassName);
        field.disabled = false;
      }
      if (unit1) unit1.classList.remove(...hiddenClassName);
      if (unit2) unit1.classList.remove(...hiddenClassName);

      this.state.set(checkbox.value, fieldId ? "" : true);
    } else {
      if (field) {
        field.classList.add(...hiddenClassName);
        field.disabled = true;
        field.value = "";
      }
      if (unit1) unit1.classList.add(...hiddenClassName);
      if (unit2) unit1.classList.add(...hiddenClassName);

      this.state.delete(checkbox.value);
    }

    this.dispatchChange();
  }

  onChangeText(event) {
    const field = event?.target;
    const id = field.name.split("[]")[1];

    this.state.set(id, field.value);

    this.dispatchChange();
  }

  dispatchChange() {
    this.dispatch("change", {
      detail: {
        id: this.idValue,
        value: this.state,
      },
    });
  }
}
