const normalizeValue = (type, value) => {
  if (["multi", "select"].includes(type)) {
    const value_map = new Map();

    if (value) {
      Object.entries(value).forEach(([key, value]) => {
        value_map.set(key, value);
      });
    }

    return value_map;
  }

  return value;
};

const normalizeDependOn = (dependOn) => {
  const dependOnArray = new Array();

  dependOn.forEach((depend_list) => {
    var dependOnMap = new Map();
    depend_list.forEach(({ parent, answers }) => {
      dependOnMap.set(parent, new Set(answers));
    });
    dependOnArray.push(dependOnMap);
  });

  return dependOnArray;
};

export const normalizeState = (initialState) => {
  const state = initialState;

  Object.values(state.fields).forEach((field) => {
    if (field.depend_on) {
      field.depend_on = normalizeDependOn(field.depend_on);
    }

    field.value = normalizeValue(field.type, field.value);
  });

  return state;
};

// Field tester

export const fieldIsFormField = (field) => {
  return ["multi", "select", "integer", "string", "float", "percent"].includes(
    field.type
  );
};

export const fieldHasValue = (field) => {
  if (["multi", "select"].includes(field.type)) {
    return field.value.size > 0;
  }

  return (
    field.value !== "" && field.value !== undefined && field.value !== null
  );
};
