import {
  Chart as ChartJS,
  PointElement,
  Legend,
  LinearScale,
  LineElement,
  LineController,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Annotation from "chartjs-plugin-annotation";

import { FONTS, COLORS } from "~/utils/chart-visuals";

// Styles

const AXES_LABEL_STYLE = {
  color: COLORS["textSecondary"],
  font: {
    ...FONTS["small"],
    weight: 600,
  },
};

const ANNOTATIONS_STYLE = {
  borderWidth: 0,
};

const DATA_LABEL_STYLE = {
  font: {
    ...FONTS["small"],
    weight: "500",
  },
  backgroundColor: COLORS["textSecondary"],
  borderRadius: "2",
  padding: {
    top: 2,
    right: 1,
    bottom: 1,
    left: 1,
  },
  color: COLORS["onAccent"],
  offset: 2,
  clamp: true,
  clip: true,
};

const TICK_MARKER = {
  color: COLORS["textSecondary"],
  font: FONTS["small"],
};

const LINE_STYLE = {
  borderColor: COLORS["textPrimary"],
  pointRadius: 2,
  borderWidth: 2,
  fill: false,
};

const LINE_TYPE_STYLES = {
  solid: {},
  dotted: {
    borderDash: [2, 4],
  },
  dashed: {
    borderDash: [6, 4],
  },
  "dashed-long": {
    borderDash: [12, 2],
  },
  mixed: {
    borderDash: [8, 4, 2, 4],
  },
  "mixed-long": {
    borderDash: [12, 2, 2, 2],
  },
};

// Helpers

const getLabelsVisibilityConfig = (hasLabels) => {
  return hasLabels
    ? {}
    : {
        datalabels: {
          labels: {
            title: false,
          },
        },
      };
};

const getTickText = (value, { unit, unitstart }) => {
  if (!unit && !unitstart) {
    return value;
  }
  if (!unit) {
    return unitstart + value;
  }
  if (!unitstart) {
    return value + unit;
  }

  return unitstart + value + unit;
};

const getLabelText = ({ label }) => {
  return label;
};

// Settings generation

export const getData = ({ lines }) => {
  return {
    datasets: lines.map(({ style, label, points, data_labels = true }) => {
      return {
        type: "line",
        label,
        data: points,
        ...getLabelsVisibilityConfig(data_labels),
        ...LINE_STYLE,
        ...LINE_TYPE_STYLES[style],
      };
    }),
  };
};

export const getOptions = ({ x, y, annotations = [] }) => ({
  animations: false,
  scales: [
    ["x", x],
    ["y", y],
  ].reduce((result, [key, scale]) => {
    return {
      ...result,
      [key]: {
        type: "linear",
        min: scale.min,
        max: scale.max,
        border: {
          display: true,
        },
        grid: {
          display: false,
        },
        title: {
          display: key == "y" && y.hideTicks ? false : true,
          text: getLabelText(scale),
          ...AXES_LABEL_STYLE,
        },
        ticks: {
          display: key == "y" && y.hideTicks ? false : true,
          autoSkip: true,
          minRotation: key == "y" ? 0 : 30,
          maxRotation: key == "y" ? 0 : 30,
          callback: (value) => getTickText(value, scale),
          ...TICK_MARKER,
        },
      },
    };
  }, {}),
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      align: "end",
      anchor: "start",
      labels: {
        title: DATA_LABEL_STYLE,
      },
      formatter: (value) => getTickText(value[1], y),
    },
    legend: {
      display: false,
    },
    annotation: {
      annotations: annotations.reduce(
        (result, { start, end, background }, index) => {
          return {
            ...result,
            [`box${index}`]: {
              drawTime: "beforeDatasetsDraw",
              type: "box",
              xMin: start,
              xMax: end,
              yMin: y.min,
              yMax: y.max,
              backgroundColor: background,
              ...ANNOTATIONS_STYLE,
            },
          };
        },
        {}
      ),
    },
  },
});

ChartJS.register(
  Annotation,
  ChartDataLabels,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement
);
