import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["error", "field", "placeholder"];

  reset() {
    this.fieldTarget.value = "";
  }

  onBeforeInput(e) {
    const patternString = e.currentTarget.getAttribute("pattern");

    if (e.data !== null && patternString) {
      const pattern = new RegExp(patternString);
      const string = [e.currentTarget.value, e.data].filter((v) => v).join("");

      if (!pattern.test(string)) {
        e.preventDefault();
      }
    }
  }

  validateRange() {
    if (this.fieldTarget.checkValidity()) {
      const min = this.fieldTarget.getAttribute("data-min");
      const max = this.fieldTarget.getAttribute("data-max");

      var error_message = "";
      if (min && max) {
        error_message =
          "Value must be between " + min + " and " + max + " (inclusive)";
      } else if (min) {
        error_message = "Value must be greater than or equal to " + min;
      } else if (max) {
        error_message = "Value must be less than or equal to " + max;
      }

      if (
        min &&
        this.fieldTarget.value &&
        this.fieldTarget.value * 1 < min * 1
      ) {
        this.fieldTarget.value = "";
        alert(error_message);
        this.onChange();
      } else if (
        max &&
        this.fieldTarget.value &&
        this.fieldTarget.value * 1 > max * 1
      ) {
        this.fieldTarget.value = "";
        alert(error_message);
        this.onChange();
      }
    }
  }

  onChange() {
    this.placeholderTarget.innerHTML = this.fieldTarget.value;

    this.dispatch("change", {
      detail: {
        id: this.element.dataset.formId,
        value: this.fieldTarget.value || undefined,
      },
    });
  }
  onBlur() {
    this.validateRange();
    const nextElement = this.fieldTarget.parentElement.nextElementSibling;
    if (nextElement && nextElement.classList.contains("hint"))
      nextElement.classList.add("hidden");
  }

  onFocus() {
    const nextElement = this.fieldTarget.parentElement.nextElementSibling;
    if (nextElement && nextElement.classList.contains("hint"))
      nextElement.classList.remove("hidden");
  }
}
