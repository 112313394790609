import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "submitAction", "consent"];
  static values = { captchaKey: String, doCaptcha: String, captchaId: String };
  captcha_set = false;

  handleInputChange() {
    if (this.doCaptchaValue == "0") this.captcha_set = true;
    this.submitActionTarget.disabled =
      this.captcha_set === false ||
      this.fieldTargets.some(
        (field) => field.value.length === 0 && field.type != "hidden"
      ) ||
      !this.consentTarget.checked;
  }

  render_recaptcha() {
    window.grecaptcha.render(document.getElementById(this.captchaIdValue), {
      sitekey: this.captchaKeyValue,
      callback: (function (t) {
        return function () {
          t.captcha_set = true;
          t.handleInputChange();
        };
      })(this),
    });
  }

  connect() {
    if (this.doCaptchaValue == "1") {
      try {
        this.render_recaptcha();
      } catch (err) {
        window.onloadCallback = (function (t) {
          return function () {
            t.render_recaptcha.call(t);
          };
        })(this);
      }
    }
  }
}
