import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
import {
  computePosition,
  flip,
  shift,
  offset,
  autoUpdate,
} from "@floating-ui/dom";

export default class extends Controller {
  static targets = ["radio", "button", "menu"];

  static classes = ["hidden", "default"];

  static values = {
    selectorText: String,
    default: String,
    defaultLabel: String,
  };

  connect() {
    useClickOutside(this);
    if (this.defaultValue != "") {
      this.updateValue({
        id: this.defaultValue,
        label: this.defaultLabelValue,
      });
    }
  }

  reset() {
    var defaultLabel = false;
    this.radioTargets.forEach((target) => {
      target.checked = false;
      if (this.defaultValue != "" && target.value == this.defaultValue) {
        target.checked = true;
        defaultLabel = this.defaultLabelValue;
      }
    });

    this.updateButton(defaultLabel);
  }

  getDefault() {
    if (this.defaultValue == "") return new Map();
    return new Map([[this.defaultValue, true]]);
  }

  clickOutside() {
    this.onToggle(true);
  }

  updatePosition() {
    this.menuTarget.style.minWidth = `${this.buttonTarget.offsetWidth}px`;

    computePosition(this.buttonTarget, this.menuTarget, {
      placement: "bottom-start",
      middleware: [offset(0), flip(), shift({ padding: 5 })],
    }).then(({ x, y }) => {
      Object.assign(this.menuTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  updateButton(label) {
    if (label) {
      this.buttonTarget.innerHTML = label;
      this.buttonTarget.classList.remove(...this.defaultClasses);
    } else {
      this.buttonTarget.innerHTML = this.selectorTextValue;
      this.buttonTarget.classList.add(...this.defaultClasses);
    }
  }

  updateValue({ label, id }) {
    this.onToggle(true);

    this.updateButton(label);

    this.dispatch("change", {
      detail: {
        id: this.element.dataset.formId,
        value: new Map([[id, true]]),
      },
    });
  }

  onToggle(close) {
    let cleanup;

    if (close === true) {
      this.menuTarget.classList.add(...this.hiddenClasses);

      if (cleanup) {
        cleanup();
      }
    } else {
      this.menuTarget.classList.remove(...this.hiddenClasses);
      cleanup = autoUpdate(
        this.buttonTarget,
        this.menuTarget,
        this.updatePosition.bind(this)
      );
    }
  }

  onSelect(event) {
    if ([" ", "Enter"].includes(event.key)) {
      const radio = this.radioTargets.find(
        (target) => target.value === event.params.id
      );

      radio.checked = true;

      this.updateValue(event.params);
    }
  }

  onChange(event) {
    this.updateValue(event.params);
  }
}
