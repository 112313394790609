import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "placeholder"];
  static values = {
    default: String,
  };

  onEnter(e) {
    e.preventDefault();
    this.inputTarget.blur();
  }

  onEsc() {
    this.inputTarget.value = this.tmpValue;
    this.placeholderTarget.innerHTML = this.tmpValue;
    this.inputTarget.blur();
  }

  onBlur() {
    const hintDiv = this.inputTarget.parentElement.querySelector("div.hint");
    hintDiv.classList.add("hidden");
    if (!this.inputTarget.value) {
      this.inputTarget.value = this.defaultValue;
      this.placeholderTarget.innerHTML = this.defaultValue;
    }
  }

  onFocus() {
    this.tmpValue = this.inputTarget.value;
    this.inputTarget.select();
  }

  onChange() {
    const hintDiv = this.inputTarget.parentElement.querySelector("div.hint");
    hintDiv.classList.add("hidden");
    this.placeholderTarget.innerHTML = this.inputTarget.value;
  }
}
