import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js";

import { getOptions, getData } from "./chart-config";

export default class extends Controller {
  static targets = ["root", "canvas"];

  connect() {
    let {
      lines,
      x_axis: x,
      y_axis: y,
      annotations,
    } = JSON.parse(this.rootTarget.dataset.chartScheme);

    new Chart(this.canvasTarget, {
      type: "bar",
      data: getData({
        lines,
      }),
      options: getOptions({
        x,
        y,
        annotations,
      }),
    });
  }
}
