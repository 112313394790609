import { Controller } from "@hotwired/stimulus";
import { normalizeState, fieldHasValue } from "~/utils/fields";

export default class extends Controller {
  static targets = ["button", "field"];

  static classes = ["hidden"];

  static values = {
    state: Object,
  };

  connect() {
    this.state = normalizeState(this.stateValue);
  }

  getFieldState(fieldId) {
    return this.state.fields[fieldId];
  }

  getFieldStates() {
    return this.state["short_survey"].map((id) => this.getFieldState(id));
  }

  updateFieldState() {
    this.getFieldStates().forEach((fieldState) => {
      if (!fieldState.dependOn) {
        return;
      }

      fieldState.visible = fieldState.dependOn.some((dependencies) => {
        return dependencies.every((dependency) => {
          const parentField = this.getFieldState(dependency.parent);

          if (!parentField) {
            return false;
          }

          return dependency.answers.some(
            (answer) =>
              parentField.value.has(answer) || parentField.value.has(answer * 1)
          );
        });
      });
    });
  }

  getVisibleFieldIds() {
    let hasBlankField = false;

    return this.getFieldStates()
      .filter(({ visible }) => visible)
      .filter((fieldState) => {
        if (hasBlankField) {
          return false;
        } else if (fieldHasValue(fieldState)) {
          return true;
        }

        hasBlankField = true;

        return true;
      })
      .map(({ id }) => id);
  }

  areAllFieldsFilled() {
    return this.getFieldStates()
      .filter(({ visible }) => visible)
      .every((fieldState) => fieldHasValue(fieldState));
  }

  updateButtonVisibility() {
    if (this.areAllFieldsFilled()) {
      this.buttonTarget.classList.remove(...this.hiddenClasses);
    } else {
      this.buttonTarget.classList.add(...this.hiddenClasses);
    }
  }

  updateDisplayOrder() {
    const ids = this.getVisibleFieldIds();
    this.fieldTargets.forEach((field) => {
      if (ids.includes(field.dataset.id)) {
        field.classList.remove(...this.hiddenClasses);
      } else {
        field.classList.add(...this.hiddenClasses);
        this.resetField(field.dataset.id);
      }
    });
  }

  update() {
    this.updateFieldState();
    this.updateDisplayOrder();
    this.updateButtonVisibility();
  }

  resetField(id) {
    const state = this.getFieldState(id);

    const element = document.querySelector(`[data-form-id="${id}"]`);

    let controllerName = ["select", "multi"].includes(state.type)
      ? `start-survey-form-${state.type}`
      : "start-survey-form-text-field";

    const controller = this.application.getControllerForElementAndIdentifier(
      element,
      controllerName
    );

    state.value = ["select", "multi"].includes(state.type)
      ? controller
        ? controller.getDefault()
        : new Map()
      : "";

    controller?.reset();
  }

  onChange({ detail: { id, value } }) {
    const fieldState = this.getFieldState(id);

    if (fieldState) {
      fieldState.value = value;
      if (["select", "multi"].includes(fieldState.type)) {
        var erase = false;
        this.fieldTargets.forEach((field) => {
          if (field.dataset.id == id) {
            erase = true;
          } else if (erase) {
            field.classList.add(...this.hiddenClasses);
            this.resetField(field.dataset.id);
          }
        });
      }
      this.update();
    }
  }

  onSubmit(e) {
    if (!this.areAllFieldsFilled()) {
      e.preventDefault();
    }
  }
}
