import { Controller } from "@hotwired/stimulus";
import { classNames } from "~/utils/class-names";
import {
  normalizeState,
  fieldHasValue,
  fieldIsFormField,
} from "~/utils/fields";

export default class extends Controller {
  static targets = [
    "button",
    "field",
    "finish",
    "counterFilled",
    "counterVisible",
    "allRequiredFilled",
  ];

  static values = {
    counterTemplate: String,
  };

  connect() {
    this.counter = 0;
    this.state = normalizeState(JSON.parse(this.element.dataset.state));
    this.classNames = classNames(JSON.parse(this.element.dataset.classNames));
    this.updateParams();
  }

  // State helpers

  getField(fieldId) {
    return this.state.fields[fieldId];
  }

  getFields() {
    return Object.values(this.state.categories)
      .flatMap((input_ids) => input_ids)
      .map((fieldId) => this.getField(fieldId))
      .filter((field) => fieldIsFormField(field));
  }

  getOtherwiseVisibleFields() {
    return this.getFields().filter(
      ({ otherwise_visible }) => otherwise_visible
    );
  }

  getFilledFields() {
    return this.getOtherwiseVisibleFields().filter((field) =>
      fieldHasValue(field)
    );
  }

  allRequriedFieldsAreFilled() {
    return Object.values(this.state.fields).every(({ required, id }) =>
      required ? fieldHasValue(this.getField(id)) : true
    );
  }

  // Updaters

  updateParams() {
    const visible = this.getOtherwiseVisibleFields().length;
    const filled = this.getFilledFields().length;
    const refineContentTarget = document.querySelector(
      "[data-report-structure-target='refineContent']"
    );

    this.counterFilledTarget.value = filled;
    this.counterVisibleTarget.value = visible;
    this.allRequiredFilledTarget.value = this.allRequriedFieldsAreFilled();

    refineContentTarget.innerHTML = this.counterTemplateValue
      .replace("{filled}", filled)
      .replace("{visible}", visible);
  }

  updateVisibility() {
    Object.values(this.state.fields).forEach((fieldState) => {
      var visibleDepends = true;
      if (fieldState.dependOn) {
        visibleDepends = fieldState.dependOn.some((dependencies) => {
          return dependencies.every((dependency) => {
            const parentField = this.getField(dependency.parent);

            if (!parentField) {
              return false;
            }

            return dependency.answers.some((answer) =>
              parentField.value.has(answer)
            );
          });
        });
      }
      fieldState.otherwise_visible = visibleDepends;
      fieldState.visible =
        visibleDepends &&
        fieldState.tier > 0 &&
        fieldState.tier < 4 &&
        !fieldState.skip &&
        !fieldHasValue(fieldState);
    });
  }

  update() {
    this.updateVisibility();
    this.updateParams();
  }

  getActiveField() {
    var activeField = null;
    this.fieldTargets.forEach((field) => {
      const fieldState = this.getField(field.dataset.id);
      if (fieldState.active) activeField = field;
    });
    return activeField;
  }

  onChange({ detail }) {
    const field = this.getField(detail.id);

    if (field) {
      field.value = detail.value;

      this.update();
    }
    this.updateButtons();
  }

  moveToNext() {
    if (this.isError()) {
      this.flashError();
    } else {
      this.counter++;
      const activeField = this.getActiveField();
      this.getField(activeField.dataset.id).skip = true;
      this.updateVisibility();
      this.showFirstVisible();
    }
  }

  onSubmit(e) {
    if (this.isError()) {
      this.flashError();
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }

  flashError() {
    const activeField = this.getActiveField();
    activeField.classList.add("flash");
    setTimeout(() => {
      activeField.classList.remove("flash");
    }, 750);
  }

  isError() {
    return this.getActiveField().classList.contains(...this.classNames.error);
  }
}
