import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

// Generic controllers
const genericControllers = import.meta.globEager(
  "../controllers/**/*_controller.js"
);

for (let path in genericControllers) {
  let module = genericControllers[path];
  let name = path
    .match(/controllers\/(.+)_controller\.js$/)[1]
    .replaceAll("/", "-")
    .replaceAll("_", "-");

  application.register(name, module.default);
}

// Controllers from components
const controllers = import.meta.globEager(
  "./../../app/views/components/**/controller.js"
);

for (let path in controllers) {
  let module = controllers[path];
  let name = path
    .match(/app\/views\/components\/(.+)\/controller\.js$/)[1]
    .replaceAll("/", "-")
    .replaceAll("_", "-");
  application.register(name, module.default);
}

export default application;
