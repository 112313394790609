import { Controller } from "@hotwired/stimulus";

/*
  ====================================
  Controlling parameters for animation
  ====================================
*/

// Color at each step
const colors = [
  "#007f40",
  "#01883f",
  "#01a53d",
  "#02b833",
  "#02cb2c",
  "#03d422",
  "#03dd1c",
  "#04e411",
  "#04ee0c",
  "#05f400",
  "#05ff00",
];
// Starting width of the first block
const startWidth = 8;
// Delay between growth stages
const delay = 800;
// Animation parameters
const animationTime = 400;
const animationSteps = 50;
// Total steps before repeating (max 11)
const maxSteps = 9;
// Angle for left/right rotation at each step
const rotAngleL = -1 * Math.asin(3 / 5);
const rotAngleR = Math.asin(4 / 5);
const easing = function (t, b, c, d) {
  return c * ((t = t / d - 1) * t * t + 1) + b;
};

const animation = (root) => {
  // Globals
  let elements;

  // Function to create element, insert in to the DOM, and return for storage
  var DrawElement = function (el) {
    const child = document.createElement("div");
    var createInverval;
    var position;
    if (el.left) {
      position = {
        left: {
          b:
            el.l - el.w * Math.sin(el.parent.left ? el.parent.a : el.parent.ar),
          e: el.l,
        },
        bottom: {
          b:
            el.b - el.w * Math.cos(el.parent.left ? el.parent.a : el.parent.ar),
          e: el.b,
        },
        angle: {
          b: el.parent.left ? el.parent.a : el.parent.ar,
          e: el.a,
        },
        z: {
          b: el.parent.step * 2 + 1,
          e: el.step * 2 + 2,
        },
      };
      child.style.backgroundColor = colors[el.step];
      child.style.width = `${el.w}em`;
      child.style.height = `${el.w}em`;
      child.style.transformOrigin = "bottom left";
      child.style.transform = "rotate(" + position.angle.b + "rad)";
      child.style.position = "absolute";
      child.style.bottom = `${position.bottom.b}em`;
      child.style.left = `${position.left.b}em`;
      child.style.zIndex = `${position.z.b}`;
      createInverval = function (child, position) {
        var step = 0;
        var id = window.setInterval(
          function (child, position) {
            step += 1;
            var newL = easing(
              step,
              position.left.b,
              position.left.e - position.left.b,
              animationSteps
            );
            var newB = easing(
              step,
              position.bottom.b,
              position.bottom.e - position.bottom.b,
              animationSteps
            );
            var newA = easing(
              step,
              position.angle.b,
              position.angle.e - position.angle.b,
              animationSteps
            );
            child.style.bottom = `${newB}em`;
            child.style.left = `${newL}em`;
            child.style.transform = "rotate(" + newA + "rad)";
            if (step == animationSteps) {
              // Complete animation
              child.style.zIndex = `${position.z.e}`;
              window.clearInterval(id);
            }
          },
          animationTime / animationSteps,
          child,
          position
        );
      };
    } else {
      var r = startWidth * 6.25 - el.l;
      position = {
        right: {
          b: r + el.w * Math.sin(el.parent.left ? el.parent.a : el.parent.ar),
          e: r,
        },
        bottom: {
          b:
            el.b - el.w * Math.cos(el.parent.left ? el.parent.a : el.parent.ar),
          e: el.b,
        },
        angle: {
          b: el.parent.left ? el.parent.a : el.parent.ar,
          e: el.ar,
        },
        z: {
          b: el.parent.step * 2 + 1,
          e: el.step * 2 + 2,
        },
      };
      child.style.backgroundColor = colors[el.step];
      child.style.width = `${el.w}em`;
      child.style.height = `${el.w}em`;
      child.style.transformOrigin = "bottom right";
      child.style.transform = "rotate(" + position.angle.b + "rad)";
      child.style.position = "absolute";
      child.style.bottom = `${position.bottom.b}em`;
      child.style.right = `${position.right.b}em`;
      child.style.zIndex = `${position.z.b}`;
      createInverval = function (child, position) {
        var step = 0;
        var id = window.setInterval(
          function (child, position) {
            step += 1;
            var newR = easing(
              step,
              position.right.b,
              position.right.e - position.right.b,
              animationSteps
            );
            var newB = easing(
              step,
              position.bottom.b,
              position.bottom.e - position.bottom.b,
              animationSteps
            );
            var newA = easing(
              step,
              position.angle.b,
              position.angle.e - position.angle.b,
              animationSteps
            );
            child.style.bottom = `${newB}em`;
            child.style.right = `${newR}em`;
            child.style.transform = "rotate(" + newA + "rad)";
            if (step == animationSteps) {
              // Complete animation
              child.style.zIndex = `${position.z.e}`;
              window.clearInterval(id);
            }
          },
          animationTime / animationSteps,
          child,
          position
        );
      };
    }
    root.appendChild(child);
    createInverval(child, position);
    return child;
  };
  // Function to advance animation one step.  Called in setTimeout to avoid blocking
  var AnimationAdvance = function (step) {
    if (step == 0) {
      // Start animation
      // Define parameters for starting block: step, width, bottom left location (b,l), angle of rotation (radians), and whether this is a 'left' or 'right' block type
      const params = {
        step: 0,
        w: startWidth,
        b: 0,
        l: startWidth * 3.1,
        a: 0,
        left: true,
        parent: {
          b: 0,
          l: startWidth * 3.1,
          a: 0,
          left: true,
        },
      };
      // Reset elements array
      elements = [
        [{ params: params, div: DrawElement(params) }],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ];
      window.setTimeout(AnimationAdvance, delay, 1);
    } else if (step == maxSteps) {
      //End of animation, reload page and check for new results
      window.location.reload();
    } else {
      // Iterate on all blocks from prior steps.  Each block grows by 2 (one left, one right)
      elements[step - 1].forEach((el) => {
        // Create child blocks
        // Calculate actual x,y location of top-left and top-right corners from which to grow left/right
        let tlB, tlL, trB, trL;

        if (el.params.left) {
          // parent is a left side block, grows off left side
          tlB = el.params.b + Math.cos(el.params.a) * el.params.w;
          tlL = el.params.l + Math.sin(el.params.a) * el.params.w;
          trB = tlB - Math.sin(el.params.a) * el.params.w;
          trL = tlL + Math.cos(el.params.a) * el.params.w;
        } else {
          // parent is a right side block, grows off right side
          trB = el.params.b - Math.sin(el.params.a) * el.params.w;
          trL = el.params.l + Math.cos(el.params.a) * el.params.w;
          tlB = trB + Math.cos(el.params.a) * el.params.w;
          tlL = trL + Math.sin(el.params.a) * el.params.w;
        }
        // Generate new left square from parent
        const params1 = {
          step: step,
          w: (el.params.w * 4) / 5,
          b: tlB,
          l: tlL,
          a: (el.params.left ? el.params.a : el.params.ar) + rotAngleL,
          ar: (el.params.left ? el.params.a : el.params.ar) + rotAngleR,
          left: true,
          parent: el.params,
        };
        const $div1 = DrawElement(params1);
        // Generate new right square from parent
        const params2 = {
          step: step,
          w: (el.params.w * 3) / 5,
          b: trB,
          l: trL,
          a: (el.params.left ? el.params.a : el.params.ar) + rotAngleL,
          ar: (el.params.left ? el.params.a : el.params.ar) + rotAngleR,
          left: false,
          parent: el.params,
        };
        var $div2 = DrawElement(params2);
        elements[step].push({ params: params1, div: $div1 });
        elements[step].push({ params: params2, div: $div2 });
      });
      window.setTimeout(AnimationAdvance, delay, step + 1);
    }
  };
  AnimationAdvance(0);
};

export default class extends Controller {
  connect() {
    animation(this.element);
  }
}
