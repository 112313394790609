import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["hidden"];
  static targets = ["closePopup"];

  onToggleClosePopup(e) {
    if (e.params.close === true) {
      this.closePopupTarget.classList.add(...this.hiddenClasses);
    } else {
      this.closePopupTarget.classList.remove(...this.hiddenClasses);
    }
  }
}
