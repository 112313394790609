import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["hidden", "active", "default"];
  static targets = ["root", "button", "tab", "closePopup", "formPopup"];

  switchTabs(e) {
    const selectedTabId = e.target.dataset.id;

    this.tabTargets.forEach((tab) => {
      if (tab.id === selectedTabId) {
        tab.classList.add("visible");
      } else {
        tab.classList.remove("visible");
      }
    });

    this.scrollToVisibleTab();

    this.buttonTargets.forEach((button) => {
      if (button === e.target) {
        button.classList.add(...this.activeClasses);
        button.classList.remove(...this.defaultClasses);
      } else {
        button.classList.remove(...this.activeClasses);
        button.classList.add(...this.defaultClasses);
      }
    });
  }

  scrollToVisibleTab() {
    this.tabTargets.forEach((tab) => {
      if (tab.classList.contains("visible")) {
        var container = tab.parentElement;
        while (container) {
          if (container.classList.contains("overflow-y-auto")) {
            break;
          }
          container = container.parentElement;
        }
        window.setTimeout(
          (function (container, tab) {
            return function () {
              const nudgeBar = document.querySelector(".nudge_bar");
              const navBar = document.querySelector(".nav_bar");
              var offset = 0;
              if (nudgeBar && nudgeBar.classList.contains("show_bar")) {
                nudgeBar.classList.remove("hidden");
                offset = nudgeBar.offsetHeight;
              }
              if (navBar && window.innerWidth <= 760)
                offset += navBar.offsetHeight;
              container.scrollTop = tab.offsetTop - 15 - offset;
            };
          })(container, tab),
          1
        );
      }
    });
  }

  showNudgePopup() {
    this.formPopupTarget.classList.remove("hidden");
  }

  onScroll() {
    var current_tab = null;
    const nudgeBar = document.querySelector(".nudge_bar");
    if (nudgeBar && nudgeBar.classList.contains("show_bar")) {
      var container = nudgeBar.parentElement;
      while (container) {
        if (container.classList.contains("overflow-y-auto")) {
          break;
        }
      }
      if (container.scrollTop > 210) nudgeBar.classList.remove("hidden");
      else nudgeBar.classList.add("hidden");
    }
    this.tabTargets.forEach((tab) => {
      var container = tab.parentElement;
      while (container) {
        if (container.classList.contains("overflow-y-auto")) {
          break;
        }
        container = container.parentElement;
      }
      if (container.scrollTop > tab.offsetTop - container.offsetHeight / 2)
        current_tab = tab.id;
    });
    if (current_tab === null) return;
    this.buttonTargets.forEach((button) => {
      if (button.dataset.id == current_tab) {
        button.classList.add(...this.activeClasses);
        button.classList.remove(...this.defaultClasses);
      } else {
        button.classList.remove(...this.activeClasses);
        button.classList.add(...this.defaultClasses);
      }
    });
  }
}
