import { COLORS } from "~/utils/chart-visuals";

// Helpers

export const getBarData = (variables) => {
  return {
    labels: Object.keys(variables),
    datasets: [
      {
        borderSkipped: false,
        data: Object.values(variables),
        backgroundColor: COLORS["accent"]
      },
    ],
  };
};

export const getBarOptions = (label, data, observations) => {
  return {
    animations: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value, index, values) {
            return (value * 100) + '%';
          }
        },
        title: {
          display: true,
          text: "Frequency (" + observations + " observation" + (observations == 1 ? ")" : "s)")
        }
      },
      x: {
        title: {
          display: true,
          text: label // Label for the x-axis
        }
      },
    },
    layout: {
      padding: {
        top: 20 // Adjust the top padding to create space for the data labels
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end', // Position the labels at the end of the bars
        align: 'top',
        clamp: true,
        offset: 0,
        display: function(context) {
          return context.dataset.data[context.dataIndex] > 0 ? 'auto' : false; // Display the label only if the value is above 0
        },
        formatter: function(value, context) {
          if(value == 0) return "";
          const percentage = value * 100; // Calculate the percentage value
          const formattedPercentage = parseFloat(percentage.toFixed(2)).toString().replace(/(\.\d*?[1-9])0+$/, '$1');
          return `${formattedPercentage}%`; // Format the percentage value as a string with two decimal places
        }
      }
    },
  };
};
