import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "errorField1", "errorField2"];

  removeErrors1() {
    this.errorField1Target.classList.add("hidden");
  }

  removeErrors2() {
    this.errorField2Target.classList.add("hidden");
  }

  registerInterest(e) {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    this.fieldTargets.some((field) => {
      if (field.id == "waitlist_email") {
        if (!emailRegex.test(field.value)) {
          this.errorField1Target.classList.remove("hidden");
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      }
    });
  }

  accessCode(e) {
    const production_codes = [
      "fermentation2023",
      "Fermentation2023",
      "fermentation_2023",
      "Fermentation_2023",
      "fermentation 2023",
      "Fermentation 2023",
    ];
    const staging_codes = ["synonym"];
    var codes;

    if (window.rails_env === "production") codes = production_codes;
    else codes = staging_codes;

    this.fieldTargets.some((field) => {
      if (field.id == "access_code") {
        if (codes.indexOf(field.value) === -1) {
          this.errorField2Target.classList.remove("hidden");
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      }
    });
  }
}
