import { COLORS } from "~/utils/chart-visuals";

// Helpers

export const getHistogramData = (variables, min_val, max_val, bins) => {
  if (min_val == "") min_val = Math.min(...variables) * 0.99;
  if (max_val == "") max_val = Math.max(...variables) * 1.01;
  if (bins == "") bins = 20;
  else bins = bins * 1;
  if (max_val < min_val) {
    const t = max_val;
    max_val = min_val;
    min_val = t;
  }
  if(max_val == min_val) {
    max_val *= 1.05;
    min_val *= 0.95
  }
  if (bins < 3) bins = 3;
  if (bins > 50) bins = 50;
  const range = max_val - min_val;
  const stepSize = range / bins;
  const frequencies = new Array(bins).fill(0);

  variables.forEach((value) => {
    const binIndex = Math.floor((value - min_val) / stepSize);
    if(binIndex >= 0 && binIndex < frequencies.length)
      frequencies[binIndex]++;
  });

  for(var i = 0; i < frequencies.length; i++) {
    frequencies[i] = frequencies[i] / variables.length;
  }

  const labels = [];
  for (let i = 0; i < bins; i++) {
    const start = min_val + i * stepSize;
    const end = min_val + (i + 1) * stepSize;
    labels.push(`${parseFloat(start.toFixed(2)).toString().replace(/(\.\d*?[1-9])0+$/, '$1')} - ${parseFloat(end.toFixed(2)).toString().replace(/(\.\d*?[1-9])0+$/, '$1')}`);
  }
  return {
    labels: labels,
    datasets: [
      {
        borderSkipped: false,
        data: frequencies,
        backgroundColor: COLORS["accent"]
      },
    ],
  };
};

export const getHistogramOptions = (label, data, observations) => {
  return {
    animations: false,
    scales: {
      y: {
        beginAtZero: true,
        max: Math.ceil(Math.max(data.datasets[0].data) * 20) / 20,
        ticks: {
          callback: function(value, index, values) {
            return (value * 100) + '%';
          }
        },
        title: {
          display: true,
          text: "Frequency (" + observations + " observation" + (observations == 1 ? ")" : "s)")
        }
      },
      x: {
        title: {
          display: true,
          text: label // Label for the x-axis
        }
      },
    },
    layout: {
      padding: {
        top: 20 // Adjust the top padding to create space for the data labels
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end', // Position the labels at the end of the bars
        align: 'top',
        clamp: true,
        offset: 0,
        display: function(context) {
          return context.dataset.data[context.dataIndex] > 0 ? 'auto' : false; // Display the label only if the value is above 0
        },
        formatter: function(value, context) {
          if(value == 0) return "";
          const percentage = value * 100; // Calculate the percentage value
          const formattedPercentage = parseFloat(percentage.toFixed(2)).toString().replace(/(\.\d*?[1-9])0+$/, '$1');
          return `${formattedPercentage}%`; // Format the percentage value as a string with two decimal places
        }
      }
    },
  };
};
