import {
  CategoryScale,
  Chart as ChartJS,
  PointElement,
  LinearScale,
  LineElement,
  BarElement,
  BarController,
  LineController,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Annotation from "chartjs-plugin-annotation";

import { FONTS, COLORS } from "~/utils/chart-visuals";

// Styles

const TICK_MARKER = {
  color: COLORS["textSecondary"],
  font: FONTS["regular"],
  padding: 0,
};

const DATALABEL_STYLE = {
  color: COLORS["accent"],
  font: {
    ...FONTS["regular"],
    weight: 600,
  },
};

const ANNOTATION_STYLE = {
  borderColor: COLORS["textSecondary"],
  borderWidth: 1,
};

// Helpers

const normalizeValue = (base_axis, variable, lasttop) => {
  const { value: variableValue, bar_type: variableType } = variable;
  var variableTop, variableBot;
  if (variableType == "waterfall") {
    variableTop = lasttop.val * 1;
    variableBot = lasttop.val * 1 + variableValue * 1;
    lasttop.val = variableBot * 1;
  } else {
    variableTop = variableValue * 1;
    variableBot = 0;
    lasttop.val = variableTop * 1;
  }
  const isInverted = variableTop > variableBot;
  return isInverted ? [variableBot, variableTop] : [variableTop, variableBot];
};

const getTickText = (value, { unitleft, unitright }) => {
  if (!unitleft && !unitright) {
    return value;
  }
  if (!unitleft) {
    return value + unitright;
  }
  if (!unitright) {
    return unitleft + value;
  }

  return unitleft + value + unitright;
};

export const getData = ({ base_axis, variables }) => {
  var lasttop = { val: 0 };
  return {
    labels: variables.map(({ label }) => label),
    datasets: [
      {
        borderRadius: 6,
        borderSkipped: false,
        data: variables.map((variable) => {
          return normalizeValue(base_axis, variable, lasttop);
        }),
        backgroundColor: variables.map((variable) => {
          return COLORS[variable.bar_type];
        }),
        datalabels: {
          labels: {
            max: {
              ...DATALABEL_STYLE,
              anchor: "end",
              align: "end",
              clamp: true,
              formatter: (_, { dataIndex }) => variables[dataIndex].tick_value,
            },
          },
        },
      },
    ],
  };
};

export const getOptions = ({ base_axis, variables }) => {
  return {
    animations: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          ...TICK_MARKER,
          callback: (value) => variables[value].label,
        },
      },
      y: {
        type: "linear",
        title: {
          display: true,
          text: base_axis.label,
        },
        min: base_axis.min,
        max: base_axis.max,
        grid: {
          display: false,
        },
        border: {
          display: true,
        },
        ticks: {
          callback: (value) => getTickText(value, base_axis),
          ...TICK_MARKER,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          bar: {
            type: "line",
            yMin: base_axis.base_value,
            yMax: base_axis.base_value,
            xMin: -1,
            xMax: variables.length,
            ...ANNOTATION_STYLE,
          },
        },
      },
    },
  };
};

ChartJS.register(
  Annotation,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  BarController,
  LineElement,
  BarElement
);
