import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
import {
  computePosition,
  flip,
  shift,
  offset,
  autoUpdate,
} from "@floating-ui/dom";

export default class extends Controller {
  static targets = ["checkbox", "button", "menu"];

  static classes = ["hidden", "default"];

  static values = {
    selectorText: String,
  };

  connect() {
    useClickOutside(this);
  }

  clickOutside() {
    this.onToggle(true);
  }

  reset() {
    this.checkboxTargets.forEach((target) => {
      target.checked = false;
    });

    this.updateButton();
  }

  getDefault() {
    return new Map();
  }

  updatePosition() {
    computePosition(this.buttonTarget, this.menuTarget, {
      placement: "bottom-start",
      middleware: [offset(0), flip(), shift({ padding: 5 })],
    }).then(({ x, y }) => {
      Object.assign(this.menuTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  updateButton(label) {
    if (label) {
      this.buttonTarget.innerHTML = label;
      this.buttonTarget.classList.remove(...this.defaultClasses);
    } else {
      this.buttonTarget.innerHTML = this.selectorTextValue;
      this.buttonTarget.classList.add(...this.defaultClasses);
    }
  }

  onToggle(close) {
    let cleanup;

    if (close === true) {
      this.menuTarget.classList.add(...this.hiddenClasses);

      if (cleanup) {
        cleanup();
      }
    } else {
      this.menuTarget.classList.remove(...this.hiddenClasses);
      cleanup = autoUpdate(
        this.buttonTarget,
        this.menuTarget,
        this.updatePosition.bind(this)
      );
    }
  }

  onChange() {
    const value = this.checkboxTargets.filter(({ checked }) => checked);
    const buttonLabel = value.map((target) => target.dataset.label).join(", ");

    this.updateButton(buttonLabel);

    this.dispatch("change", {
      detail: {
        id: this.element.dataset.formId,
        value: new Map(value.map(({ value }) => [value, true])),
      },
    });
  }
}
