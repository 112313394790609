import {
  CategoryScale,
  Chart as ChartJS,
  PointElement,
  LinearScale,
  LineElement,
  BarElement,
  BarController,
  LineController,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Annotation from "chartjs-plugin-annotation";

import { FONTS, COLORS } from "~/utils/chart-visuals";

// Styles

const TICK_MARKER = {
  color: COLORS["textSecondary"],
  font: FONTS["regular"],
  padding: 0,
};

const DATALABEL_STYLE = {
  color: COLORS["accent"],
  font: {
    ...FONTS["regular"],
    weight: 600,
  },
};

const ANNOTATION_STYLE = {
  borderDash: [4, 4],
  borderColor: COLORS["textPrimary"],
};

// Helpers

const normalizeValue = (base_axis, variable) => {
  const { left: variableLeft, right: variableRight } = variable;
  const isInverted = variableLeft > variableRight;
  return isInverted
    ? [variableRight, variableLeft]
    : [variableLeft, variableRight];
};

const getTickText = (value, { unitleft, unitright }) => {
  if (!unitleft && !unitright) {
    return value;
  }
  if (!unitleft) {
    return value + unitright;
  }
  if (!unitright) {
    return unitleft + value;
  }

  return unitleft + value + unitright;
};

export const getData = ({ base_axis, variables }) => {
  return {
    labels: variables.map(({ label }) => label),
    datasets: [
      {
        borderRadius: 6,
        borderSkipped: false,
        data: variables.map((variable) => {
          return normalizeValue(base_axis, variable);
        }),
        backgroundColor: COLORS["accent"],
        datalabels: {
          labels: {
            min: {
              ...DATALABEL_STYLE,
              anchor: "start",
              align: "start",
              clamp: true,
              formatter: (_, { dataIndex }) => variables[dataIndex].left_value,
            },
            max: {
              ...DATALABEL_STYLE,
              anchor: "end",
              align: "end",
              clamp: true,
              formatter: (_, { dataIndex }) => variables[dataIndex].right_value,
            },
          },
        },
      },
    ],
  };
};

export const getOptions = ({ base_axis, variables }) => {
  return {
    animations: false,
    scales: {
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          ...TICK_MARKER,
          callback: (value) => variables[value].label,
        },
      },
      x: {
        type: "linear",
        min: base_axis.left,
        max: base_axis.right,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          callback: (value) => getTickText(value, base_axis),
          ...TICK_MARKER,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          bar: {
            type: "line",
            xMin: base_axis.base_value,
            xMax: base_axis.base_value,
            yMin: -1,
            yMax: variables.length,
            ...ANNOTATION_STYLE,
          },
        },
      },
    },
  };
};

ChartJS.register(
  Annotation,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  BarController,
  LineElement,
  BarElement
);
