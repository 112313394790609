import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slide", "dot"];

  connect() {
    const onIntersectionObserved = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const intersectingIndex = this.slideTargets.indexOf(entry.target);
          this.updateDots(intersectingIndex);
        }
      });
    };

    const observer = new IntersectionObserver(onIntersectionObserved, {
      root: this.element,
      rootMargin: "0px",
      threshold: 0.9,
    });

    this.slideTargets.forEach((item) => {
      observer.observe(item);
    });
  }

  updateDots(selectedIndex) {
    this.dotTargets.forEach((target, index) => {
      if (selectedIndex === index) {
        target.classList.add("bg-accent");
      } else {
        target.classList.remove("bg-accent");
      }
    });
  }
}
