import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["navigation"];

  onToggleMenu({ params }) {
    if (params.open) {
      this.element.classList.add(...this.navigationClasses);
    } else {
      this.element.classList.remove(...this.navigationClasses);
    }
  }
}
