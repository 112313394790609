import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["hidden"];
  static values = ["persistent"];
  static targets = ["root"];

  onClose(e) {
    if (this.persistentValue || e.target !== e.currentTarget) {
      return;
    }

    this.rootTarget.classList.add(...this.hiddenClasses);
  }
}
