import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mobileMenu"];

  lockBodyScroll() {
    document.body.classList.add("overflow-hidden");
  }

  unlockBodyScroll() {
    document.body.classList.remove("overflow-hidden");
  }

  openMobileMenu() {
    this.lockBodyScroll();
    this.mobileMenuTarget.classList.remove("hidden");
  }

  closeMobileMenu() {
    this.unlockBodyScroll();
    this.mobileMenuTarget.classList.add("hidden");
  }
}
