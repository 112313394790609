import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["error", "field"];
  static values = {
    isError: Boolean,
    hiddenClassName: String,
    errorClassName: String,
    defaultClassName: String,
  };

  connect() {
    this.hiddenClassName = this.hiddenClassNameValue.split(" ");
    this.errorClassName = this.errorClassNameValue.split(" ");
    this.defaultClassName = this.defaultClassNameValue.split(" ");
    this.isError = this.isErrorValue;
  }

  onChange() {
    if (this.isError) {
      if (this.errorTarget) {
        this.errorTarget.classList.add(...this.hiddenClassName);
      }

      this.fieldTarget.classList.remove(...this.errorClassName);
      this.fieldTarget.classList.add(...this.defaultClassName);
    }

    this.isError = false;
  }
}
