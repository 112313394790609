import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["textArea"];

  connect() {
    if (this.textAreaTarget.scrollHeight > 70) {
      //multi-line...break on 'to'
      this.textAreaTarget.innerHTML = this.textAreaTarget.innerHTML.replace(
        " to ",
        "<div>to</div>"
      );
    }
  }
}
