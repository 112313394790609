export const FONT_FAMILY = "Inter, sans-serif";

export const FONTS = {
  small: {
    family: FONT_FAMILY,
    size: 10,
  },
  regular: {
    family: FONT_FAMILY,
    size: 10,
  },
};

export const COLORS = {
  textPrimary: "rgba(34, 27, 47, 1)",
  textSecondary: "rgba(34, 27, 47, 0.6)",
  onAccent: "rgba(255, 255, 255, 1)",
  accent: "#00884F",
  waterfall: "#b6f4c1",
};
