import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js";

import { getOptions, getData } from "./chart-config";

export default class extends Controller {
  static targets = ["root", "canvas"];

  connect() {
    let { base_axis, variables } = JSON.parse(
      this.rootTarget.dataset.chartScheme
    );
    new Chart(this.canvasTarget, {
      type: "bar",
      data: getData({ base_axis, variables }),
      options: getOptions({ base_axis, variables }),
    });
  }
}
