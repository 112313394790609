import { Controller } from "@hotwired/stimulus";
import { classNames } from "~/utils/class-names";
import {
  normalizeState,
  fieldHasValue,
  fieldIsFormField,
} from "~/utils/fields";

export default class extends Controller {
  static targets = [
    "button",
    "field",
    "finish",
    "counterFilled",
    "counterVisible",
    "allRequiredFilled",
  ];

  static values = {
    counterTemplate: String,
  };

  connect() {
    this.counter = 0;
    this.state = normalizeState(JSON.parse(this.element.dataset.state));
    this.classNames = classNames(JSON.parse(this.element.dataset.classNames));
    this.showFirstVisible();
    this.showNudge();
    this.updateParams();
  }

  // State helpers

  getField(fieldId) {
    return this.state.fields[fieldId];
  }

  getFields() {
    return Object.values(this.state.categories)
      .flatMap((input_ids) => input_ids)
      .map((fieldId) => this.getField(fieldId))
      .filter((field) => fieldIsFormField(field));
  }

  getOtherwiseVisibleFields() {
    return this.getFields().filter(
      ({ otherwise_visible }) => otherwise_visible
    );
  }

  getFilledFields() {
    return this.getOtherwiseVisibleFields().filter((field) =>
      fieldHasValue(field)
    );
  }

  allRequriedFieldsAreFilled() {
    return Object.values(this.state.fields).every(({ required, id }) =>
      required ? fieldHasValue(this.getField(id)) : true
    );
  }

  // Updaters

  updateParams() {
    const visible = this.getOtherwiseVisibleFields().length;
    const filled = this.getFilledFields().length;
    const refineContentTarget = document.querySelector(
      "[data-report-structure-target='refineContent']"
    );

    this.counterFilledTarget.value = filled;
    this.counterVisibleTarget.value = visible;
    this.allRequiredFilledTarget.value = this.allRequriedFieldsAreFilled();

    refineContentTarget.innerHTML = this.counterTemplateValue
      .replace("{filled}", filled)
      .replace("{visible}", visible);
  }

  updateVisibility() {
    Object.values(this.state.fields).forEach((fieldState) => {
      var visibleDepends = true;
      if (fieldState.dependOn) {
        visibleDepends = fieldState.dependOn.some((dependencies) => {
          return dependencies.every((dependency) => {
            const parentField = this.getField(dependency.parent);

            if (!parentField) {
              return false;
            }

            return dependency.answers.some((answer) =>
              parentField.value.has(answer)
            );
          });
        });
      }
      fieldState.otherwise_visible = visibleDepends;
      fieldState.visible =
        visibleDepends &&
        fieldState.tier > 0 &&
        fieldState.tier < 4 &&
        !fieldState.skip &&
        !fieldHasValue(fieldState);
    });
  }

  showFirstVisible() {
    var showItem = true;
    this.fieldTargets.forEach((field) => {
      const fieldState = this.getField(field.dataset.id);

      if (fieldState?.visible && showItem) {
        fieldState.active = true;
        field.classList.remove(...this.classNames.hidden);
        showItem = false;
      } else {
        fieldState.active = false;
        field.classList.add(...this.classNames.hidden);
      }
    });
    this.updateButtons();
  }

  showNudge() {
    const activeField = this.getActiveField();
    const nudgeDiv = this.element.parentNode.closest(".nudge_message");
    const nudgeBar = document.querySelector(".nudge_bar");
    window.setTimeout(
      (function (activeField, nudgeDiv) {
        return function () {
          if (activeField !== null) {
            nudgeDiv.classList.remove("hidden");
            const height = nudgeDiv.scrollHeight + 36;
            nudgeDiv.style.overflow = "hidden";
            nudgeDiv.style.height = "0px";
            const duration = 500;
            let startTime = null;

            const animationStep = function (timestamp) {
              if (!startTime) {
                startTime = timestamp;
              }

              const timeElapsed = timestamp - startTime;
              const animationProgress = Math.sin(
                (timeElapsed / duration) * (Math.PI / 2)
              );

              nudgeDiv.style.height = `${animationProgress * height}px`;

              if (timeElapsed < duration) {
                window.requestAnimationFrame(animationStep);
              } else {
                nudgeDiv.style.height = "auto";
                nudgeDiv.style.overflow = "auto";
              }
            };
            window.requestAnimationFrame(animationStep);
          }
        };
      })(activeField, nudgeDiv),
      1750
    );
    nudgeBar.classList.add("show_bar");
  }

  updateButtons() {
    const activeField = this.getActiveField();
    if (activeField === null) {
      this.finishTarget.classList.remove(...this.classNames.hidden);
    }
    this.buttonTargets.forEach((button) => {
      if (button.dataset.label == "submit") {
        if (this.counter >= 2 || activeField === null)
          button.classList.remove(...this.classNames.hidden);
        else button.classList.add(...this.classNames.hidden);
      }
      if (activeField === null) {
        if (button.dataset.label == "next")
          button.classList.add(...this.classNames.hidden);
        if (button.dataset.label == "skip")
          button.classList.add(...this.classNames.hidden);
      } else {
        if (button.dataset.label == "next") {
          if (fieldHasValue(this.getField(activeField.dataset.id)))
            button.classList.remove(...this.classNames.hidden);
          else button.classList.add(...this.classNames.hidden);
        }
        if (button.dataset.label == "skip") {
          if (!fieldHasValue(this.getField(activeField.dataset.id)))
            button.classList.remove(...this.classNames.hidden);
          else button.classList.add(...this.classNames.hidden);
        }
      }
    });
  }

  update() {
    this.updateVisibility();
    this.updateParams();
  }

  getActiveField() {
    var activeField = null;
    this.fieldTargets.forEach((field) => {
      const fieldState = this.getField(field.dataset.id);
      if (fieldState.active) activeField = field;
    });
    return activeField;
  }

  onChange({ detail }) {
    const field = this.getField(detail.id);

    if (field) {
      field.value = detail.value;

      this.update();
    }
    this.updateButtons();
  }

  moveToNext() {
    if (this.isError()) {
      this.flashError();
    } else {
      this.counter++;
      const activeField = this.getActiveField();
      this.getField(activeField.dataset.id).skip = true;
      this.updateVisibility();
      this.showFirstVisible();
    }
  }

  onSubmit(e) {
    if (this.isError()) {
      this.flashError();
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }

  flashError() {
    const activeField = this.getActiveField();
    activeField.classList.add("flash");
    setTimeout(() => {
      activeField.classList.remove("flash");
    }, 750);
  }

  isError() {
    return this.getActiveField().classList.contains(...this.classNames.error);
  }
}
