import { Controller } from "@hotwired/stimulus";
import { normalizeState } from "~/utils/fields";
import { Chart } from "chart.js";
import { getBarOptions, getBarData } from "./bar-config";
import { getHistogramOptions, getHistogramData } from "./histogram-config";

export default class extends Controller {
  static targets = ["field", "display", "plotPopup", "filters", "filtersi", "body", "pencil", "trash"];
  plots = {};
  choices;
  id = 0;
  filters = [];

  connect() {
    this.state = normalizeState(JSON.parse(this.element.dataset.state));
    this.bartype = JSON.parse(this.element.dataset.bartype);
    this.datatable = JSON.parse(this.element.dataset.datatable);
    this.input_options = {}
    JSON.parse(this.element.dataset.inputoptions).forEach((option) => {
      this.input_options[option[1]] = option[0];
    });
    var labels = JSON.parse(this.element.dataset.datalabels);
    this.datalabels = {}
    var count = 0;
    labels.forEach((label) => {
      this.datalabels[label] = count;
      count += 1;
    })
    this.updateVisibility();
    this.choices = new Choices('#to_display', {searchFields: ['label']});
 
    const selectTags = this.filtersTargets.map(selectContainer => selectContainer.querySelectorAll('select'));

    // Make all filters magic selects
    selectTags.forEach(selects => {
      selects.forEach(select => {
        this.filters.push(new Choices(select, {searchEnabled: false})); 
      });
    });
    this.resetPlots();
    document.addEventListener('click', function($this) { return function(event) {
      const targetElement = event.target;
      const active_plot = $this.active_plot();
      if(active_plot == null) return;

      const plotElement = document.getElementById('plot_' + active_plot.id);
      const filterElement = document.getElementById('filters_active');

      // Check if the container element or any of its ancestors contain the target element
      if (filterElement.contains(targetElement) || plotElement.contains(targetElement)) {
        return true;
      } else {
        // Click occurred outside the container
        $this.deactivate();
        return true;
      }
    }; }(this));
    window.addEventListener('resize', function($this) { return function() {
      $this.redrawPlots();
    } }(this));
  }

  redrawPlots() {
    Object.keys(this.plots).forEach((plot_id) => {
      const el = document.getElementById('plot_' + plot_id);
      el.style.flexGrow = '0'; // Set flex-grow to 0
    });
    Object.keys(this.plots).forEach((plot_id) => {
      if('chart' in this.plots[plot_id]) {
        this.plots[plot_id].chart.resize();
      }
    });
    Object.keys(this.plots).forEach((plot_id) => {
      const el = document.getElementById('plot_' + plot_id);
      el.style.flexGrow = '1'; // Set flex-grow to 0
    });
    Object.keys(this.plots).forEach((plot_id) => {
      if('chart' in this.plots[plot_id]) {
        this.plots[plot_id].chart.resize();
      }
    });
  }

  addNewPlot(e) {
    this.plotPopupTarget.classList.remove("hidden");
  }

  resetPlots(e) {
    this.plots = { };
    this.plots[this.id] = {
      id: this.id,
      var: "o|overview|overall_costs4|opEx",
      active: false,
      filters: {},
      min: "",
      max: "",
      bins: ""
    };
    this.clearFilters();
    this.bodyTarget.innerHTML = "";
    this.createPlot(this.id);
  }

  closePopup(e) {
    this.plotPopupTarget.classList.add("hidden");
    this.choices.setChoiceByValue("");
  }

  selectPlot(e) {
    if (this.choices.getValue(true) == "" ) {
      alert("Select a parameter to display");
      e.preventDefault();
    } else {
      this.id += 1;
      this.plots[this.id] = {
        id: this.id,
        var: this.choices.getValue(true),
        active: false,
        filters: {},
        min: "",
        max: "",
        bins: ""
      }
      this.clearFilters();
      this.getFilters(this.id);
      this.plotPopupTarget.classList.add("hidden");
      this.createPlot(this.id);
      this.activate(this.id);
    }

    this.choices.setChoiceByValue("");
  }

  getFilters(id) {
    this.filters.forEach((filter) => {
      this.plots[id].filters[filter.passedElement.element.name] = filter.getValue(true);
    });
    this.plots[id].min = document.getElementById('min_val').value * 1;
    this.plots[id].max = document.getElementById('max_val').value * 1;
    this.plots[id].bins = document.getElementById('bins').value * 1;
  }

  setFilters(id) {
    this.filters.forEach((filter) => {
      filter.setChoiceByValue(this.plots[id].filters[filter.passedElement.element.name])
      const field = this.getField(filter.passedElement.element.name.substring(6));
      if (field) {
        field.value = [this.plots[id].filters[filter.passedElement.element.name]];
      } 
    });
    document.getElementById('min_val').value = this.plots[id].min;
    document.getElementById('max_val').value = this.plots[id].max;
    document.getElementById('bins').value = this.plots[id].bins;
    this.updateVisibility();
  }

  clearFilters() {
    this.filters.forEach((filter) => {
      filter.setChoiceByValue("");
      const field = this.getField(filter.passedElement.element.name.substring(6));
      if (field) {
        field.value = "";
      }
    });
    document.getElementById('min_val').value = "";
    document.getElementById('max_val').value = "";
    document.getElementById('bins').value = "";
  }

  editPlot(e) {
    this.activate(e.target.closest('[data-id]').getAttribute('data-id') * 1);
  }

  deletePlot(e) {
    const id = e.target.closest('[data-id]').getAttribute('data-id') * 1;
    document.getElementById('plot_' + id).remove();
    delete this.plots[id];
    this.clearFilters();
    this.redrawPlots();
    if(this.active_plot() == null) {
      this.filtersTarget.classList.add("hidden");
      this.filtersiTarget.classList.remove("hidden");
    } else {
      this.filtersiTarget.classList.add("hidden");
      this.filtersTarget.classList.remove("hidden");
    }
  }

  activate(id) {
    Object.keys(this.plots).forEach((plot_id) => {
      this.plots[plot_id].active = false;
      document.getElementById('plot_' + plot_id).classList.remove("selected");
    })
    this.plots[id].active = true;
    this.setFilters(id);
    document.getElementById('plot_' + id).classList.add("selected");
    this.filtersiTarget.classList.add("hidden");
    this.filtersTarget.classList.remove("hidden");
    if(this.bartype[this.plots[id].var])
      document.getElementById('histogram_options').classList.add("hidden");
    else
      document.getElementById('histogram_options').classList.remove("hidden");
  }

  deactivate() {
    Object.keys(this.plots).forEach((plot_id) => {
      this.plots[plot_id].active = false;
      document.getElementById('plot_' + plot_id).classList.remove("selected");
    });
    this.clearFilters();
    this.filtersTarget.classList.add("hidden");
    this.filtersiTarget.classList.remove("hidden");
  }

  active_plot() {
    for (const plot_id in this.plots) {
      if (this.plots[plot_id].active) return this.plots[plot_id];
    }
    return null;
  }

  onSubmit(e) {
    e.preventDefault();
    this.getFilters(this.active_plot().id);
    this.refreshPlot(this.active_plot().id);
    if(window.screen.width <= 760)
      this.deactivate();
  }

  refreshPlot(plot_id) {
    if(this.bartype[this.plots[plot_id].var])
      this.plotBar(plot_id);
    else
      this.plotHistogram(plot_id);
  }

  createPlot(plot_id) {
    var html = "<div class='plot' id='plot_" + plot_id + "' style='padding: 20px; position: relative;flex-grow: 1;width: 400px;overflow: hidden;'"
    if(this.plots[plot_id].active)
      html += "class='selected'"
    html += "'><canvas class='body' style='min-height:300px;'></canvas><div data-action='click->explore-data#editPlot' data-id='" + plot_id + "' class='edit_plot rounded-full bg-surface-2-ui text-text-primary p-3'>"
    html += this.pencilTarget.innerHTML;
    html += "</div><div data-action='click->explore-data#deletePlot' data-id='" + plot_id + "' class='delete_plot rounded-full bg-surface-2-ui text-text-primary p-3'>"
    html += this.trashTarget.innerHTML;
    html += "</div></div>";
    this.bodyTarget.insertAdjacentHTML('afterbegin', html);
    this.refreshPlot(plot_id);
    this.redrawPlots();
  }

  refreshPlots() {
    Object.keys(this.plots).forEach((plot_id) => {
      this.refreshPlot(plot_id);
    });
    if(this.active_plot() == null) {
      this.filtersTarget.classList.add("hidden");
      this.filtersiTarget.classList.remove("hidden");
    } else {
      this.filtersiTarget.classList.add("hidden");
      this.filtersTarget.classList.remove("hidden");
    }
  }

  filterData(plot_id) {
    var filters = []
    Object.keys(this.plots[plot_id].filters).forEach((filter) => {
      if(this.plots[plot_id].filters[filter] != "") {
        var new_id = filter.substring(6);
        if(new_id.substring(18) == "numberOfFermentors")
          new_id = "s|numberOfFermentors";
        else
          new_id = "i|" + new_id;
        filters.push({ id: new_id, val: this.plots[plot_id].filters[filter] });
      }
    })
    var filtered_data = []
    this.datatable.forEach((row) => {
      var addit = true;
      for(var i = 0; i < filters.length; i++) {
        if(row[this.datalabels[filters[i].id]] != filters[i].val) {
          addit = false;
          break;
        }
      }
      if(addit)
        filtered_data.push(row[this.datalabels[this.plots[plot_id].var]]);
    })
    return filtered_data;
  }

  plotHistogram(plot_id) {
    let variables = this.filterData(plot_id);
    let min_val = this.plots[plot_id].min;
    let max_val = this.plots[plot_id].max;
    let bins = this.plots[plot_id].bins;
    if('chart' in this.plots[plot_id])
      this.plots[plot_id].chart.destroy();
    var data = getHistogramData(variables, min_val, max_val, bins);
    this.plots[plot_id].chart = new Chart(document.getElementById('plot_' + plot_id).querySelector('canvas.body'), {
      type: "bar",
      data: data,
      options: getHistogramOptions(this.input_options[this.plots[plot_id].var], data, variables.length),
    });
  }

  plotBar(plot_id) {
    let variables = this.filterData(plot_id);
    var bar_data = {};
    variables.forEach((variable) => {
      if(variable in bar_data)
        bar_data[variable] += 1 / variables.length;
      else
        bar_data[variable] = 1 / variables.length;
    })
    var data = getBarData(bar_data);
    if('chart' in this.plots[plot_id])
      this.plots[plot_id].chart.destroy();
    this.plots[plot_id].chart = new Chart(document.getElementById('plot_' + plot_id).querySelector('canvas.body'), {
      type: "bar",
      data: data,
      options: getBarOptions(this.input_options[this.plots[plot_id].var], data, variables.length),
    });
  }

  updateVisibility() {
    Object.values(this.state.fields).forEach((fieldState) => {
      if (!fieldState.dependOn) {
        return;
      }

      fieldState.visible = fieldState.dependOn.some((dependencies) => {
        return dependencies.every((dependency) => {
          const parentField = this.getField(dependency.parent);

          if (!parentField) {
            return false;
          }

          if (parentField.value instanceof Map) {
            parentField.value = Array.from(
              parentField.value,
              ([value]) => value
            );
          }

          return dependency.answers.some(
            (answer) =>
              Array.isArray(parentField.value) &&
              (parentField.value.includes(answer) ||
                parentField.value.includes(answer * 1))
          );
        });
      });
    });

    this.fieldTargets.forEach((field) => {
      const fieldState = this.getField(field.dataset.id);

      if (fieldState?.visible) {
        field.classList.remove("hidden");
      } else {
        field.classList.add("hidden");
        field.querySelector("select").value = "";
        fieldState.value = null;
        this.filters.forEach((filter) => {
          if(filter.passedElement.element.name == ("input_" + field.dataset.id)) filter.setChoiceByValue("");
        })
      }
    });
  }

  onChange(e) {
    const field = this.getField(e.target.dataset.id);

    if (field) {
      field.value = this.getSelectValues(e.target);
      this.updateVisibility();
    }
  }

  getField(fieldId) {
    return this.state.fields[fieldId];
  }

  getSelectValues(select) {
    var result = [];
    var options = select && select.options;
    var opt;

    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    return result;
  }
}
